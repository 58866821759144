.account-reviews-list {
  &__subline {
    margin: 40px 0 10px;
    padding: 0 0 15px;
    border-bottom: 1px solid $textDarkestColor;
  }

  &__intro {
    margin: 0;
    padding: 0 0 30px;
  }

  &__top-bar {
    margin: 10px 0;
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: space-between;
      min-height: 45px;
    }

    @media (min-width: $screen-desktop) {
      justify-content: flex-start;
      margin: 20px 0;
    }

    &--empty {
      @media (min-width: $screen-tablet-portrait) {
        display: none !important;
      }
    }

    .products-sort,
    .pagination {
      @media (min-width: $screen-desktop) {
        width: 33.33%;
      }
    }

    .products-sort--center {
      @media (min-width: $screen-desktop) {
        justify-content: center;
      }
    }

    .products-sort .select {
      margin: 0;

      .select__wrapper .select__selected-value {
        padding: 0 16px 0 0;

        @media (min-width: $screen-tablet-portrait) {
          padding: 0 16px;
        }
      }
    }

    .products-sort__label {
      top: 6px;
      left: 0;
    }
  }

  &__bottom-bar {
    margin: 0 0 10px;
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (min-width: $screen-tablet-portrait) {
      min-height: 45px;
    }

    @media (min-width: $screen-desktop) {
      margin: 0 0 20px;
    }
  }

  &__info-headline {
    font-size: 18px;
    font-weight: $fontWeightSemiBold;
    text-align: center;
    margin: 0 0 10px;
    width: 100%;
  }

  &__list {
    position: relative;
  }

  &__user-profile {
    background: $whiteColor;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / .5);
    padding: 16px 8px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 16px;
    }
  }

  &__grid {
    margin: 32px 0;
  }

  &__products {
    display: flex;
    margin: 0 -6px;
    flex-wrap: wrap;
  }

  .products-list__element--type_product {
    width: 100%;
    padding: 5px 5px 15px;

    @media (min-width: $screen-mobile-landscape) {
      width: 50%;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding: 6px 6px 24px;
    }

    @media (min-width: $screen-desktop) {
      width: calc(100% / 3);
    }
  }
}
