.account-recurring-order-skip {
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.form {
      margin: 0;
    }

    &__check {
      font-weight: $fontWeightSemiBold;
    }

    &__explanation {
      font-size: $fontSizeSmall;
      margin-bottom: 8px;
    }

    .account-recurring-order-item__button--submit {
      order: $accountRecurringOrderItemSubmitOrder;
      text-transform: uppercase;
    }
  }
}
