.hermes-widget {
  &__iframe-wrapper {
    height: 100%;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__side-panel {
    background: #fff;

    .side-panel__container {
      overflow: hidden;
    }
  }

  &__logo {
    > span {
      display: inline-block;
      position: relative;
      transform: scale(.7);
      margin-right: 5px;
    }
  }

  &__action-bar {
    position: relative;
    margin-top: 10px;
    padding: 0 5px 10px;
  }
}
