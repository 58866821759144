.account-recurring-order-shipping-date {
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.form {
      margin: 0;
    }

    &__explanation {
      font-size: $fontSizeSmall;
      margin: 8px 0;
    }

    .date-input .input__wrapper {
      background: $accountRecurringOrderShippingDateBackground;
    }

    .account-recurring-order-item__button--submit {
      order: $accountRecurringOrderItemSubmitOrder;
      text-transform: uppercase;
    }
  }
}
