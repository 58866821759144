.checkout-step-register {
  @include container($maxWidthInner);
  padding-top: 50px;
  padding-bottom: 0;

  &__content {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 0;
  }
}
