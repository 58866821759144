.order-summary-small {
  $root: &;
  width: 100%;
  margin: 0;

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0;

    &--no-margin {
      margin: 0;
    }

    &--discount {
      position: relative;

      .order-summary-small__value {
        color: $textSuccessColor;
      }
    }

    &--shipping-free {
      .order-summary-small__value {
        color: $textSuccessColor;
      }
    }

    &--full {
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-end;
      margin: 0;
    }

    &--summary,
    &--border-top {
      margin-top: 2px;
      border-top: 1px solid $grayLightColor;
      padding-top: 6px;
    }
  }

  &__key,
  &__value {
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    font-size: 14px;
    line-height: 1.4;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 16px;
    }

    &--bold {
      font-weight: $fontWeightBold;
    }

    #{$root}__row--sub-total &,
    #{$root}__row--total & {
      font-weight: $fontWeightBold;
    }
  }

  &__key {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 6px;
    flex-grow: 2;

    #{$root}__row--extra-promotion & {
      flex-wrap: wrap;
      gap: 0;
    }

    #{$root}__row--shipping & {
      gap: 0;
      flex-direction: column;
    }
  }

  &__value {
    text-wrap: nowrap;
  }

  &__small-text {
    display: block;
    font-weight: $orderSummarySmallSmallTextFontWeight;
    font-size: $orderSummarySmallSmallTextFontSize;
  }

  &__key-list-item {
    display: block;
  }
}
