@use "sass:math";

@mixin customScrollbar(
  $size: 4px,
  $trackColor: $customScrollbarTrackColor,
  $thumbColor: $customScrollbarThumbColor,
  $thumbHoverColor: $customScrollbarThumbHoverColor
) {
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-track {
    background: $trackColor;
    border-radius: math.div($size, 2);
  }

  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: math.div($size, 2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $thumbHoverColor;
  }
}
