.register-address-form {
  &__option {
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;

    &--hermes_paketshop {
      background-image: url(../staticImages/delivery-type-hermes.png);
    }
  }

  &__billing-address {
    &--collapsed {
      .input--disabled,
      .select--disabled,
      .radio-input--inline{
        display: none;
      }
    }
  }

  .radio-input {
    align-items: center;

    &__label {
      font-size: $fontSizeBase;
      font-weight: $fontWeightMedium;
    }
  }

  .form__option-area {
    background: $registerAddressFormOptionAreaBackground;
  }
}
