@import '../../../bps/components/productOrderPosition/productOrderPosition.scss';

.product-order-position {
  $root: &;

  &__name {
    margin-bottom: 11px;
    font-weight: $fontWeightRegular;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__product-info,
  &__product-nr,
  &__variations,
  &__delivery-info {
    color: $brandPrimaryColor;
    font-size: $fontSizeXXSmall;
    line-height: 1;
    font-weight: $fontWeightMedium;

    @media (min-width: $screen-tablet-landscape) {
      font-size: $fontSizeXSmall;
      line-height: 1;
    }
  }

  &__cell {
    &--price {
      .price-info__unit {
        display: none;
      }
    }

    &--summary {
      .price-info__value {
        order: 2;
      }
    }
  }

  &__mm-exclude {
    background: $milesAndMoreColor;
    color: $productOrderPositionPromotionText;
    font-size: $productOrderPositionPromotionFontSize;
    margin: 4px 4px 0 0;
    padding: 4px;
    text-align: center;
    display: inline-block;
  }

  .price-info {
    &__current {
      justify-content: flex-end;
      font-size: $fontSizeSmall !important;

      @media (min-width: $screen-tablet-landscape) {
        font-size: $baseFontSize !important;
      }
    }

    &__old {
      text-align: right;
      font-size: 10px;
    }

    &__value {
      letter-spacing: 0;
      align-items: flex-end;

      &-cent {
        margin: 0;
        font-size: 1em;

        &::before {
          content: ',';
        }
      }
    }

    &__savedPercentage {
      transform: translateX(100%);

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 0 0 5px;
        padding: 4px 9px;
      }
    }

    &--reduction {
      position: relative;

      @media (min-width: $screen-tablet-portrait) {
        bottom: 9px;
      }
    }
  }

  .availability-info {
    &--statement {
      @include fontSize($fontSizeXSmall);
    }

    &--label {
      @include fontSize($fontSizeSmall);
      font-weight: $fontWeightMedium;
    }
  }

  &--free-item {
    background-color: $whiteColor;

    .product-order-position__name {
      color: $productOrderPositionNameColor;
    }

    .price-info__savedPercentage { // stylelint-disable-line
      display: none;
    }

    .counter--disabled {
      border-color: $whiteColor;

      .counter__input--disabled,
      .counter__input[disabled] {
        background-color: $whiteColor;
        font-size: 18px;
        -webkit-text-fill-color: $textBaseColor;
        opacity: 1;
      }
    }
  }
}
