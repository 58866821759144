@import '../../../bps/components/deliveryOptionsForm/deliveryOptionsForm.scss';

.delivery-option-form {
  $root: &;

  &__forecast-wrapper {
    margin-bottom: 8px;
    padding-bottom: 8px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 12px;
      padding-bottom: 12px;
    }
  }

  &__combine-wrapper {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid $blackColor;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 12px;
      padding-bottom: 12px;
    }

    .checkbox__label {
      font-size: $fontSizeSmall;
      padding-right: 8px;
    }

    .delivery-forecast-message {
      padding-left: 29px;
    }
  }

  .radio-input {
    &__radio {
      top: 3px;
    }

    &__info {
      margin: 4px 4px 0 0;
    }
  }
}
