.order-summary {
  $root: &;

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0;
    min-height: 25px;

    @media (min-width: $screen-tablet-landscape) {
      min-height: 30px;
    }

    &--savings {
      min-height: 34px;

      #{$root}__value {
        color: $successColor;
      }
    }

    &--error {
      &:empty {
        display: none;
      }

      #{$root}__key {
        color: $errorColor;
        font-size: 12px;
        max-width: calc(100% - 100px);

        @media (min-width: $screen-tablet-landscape) {
          font-size: 14px;
        }
      }
    }

    &--promotion,
    &--extra-promotion,
    &--sub-total,
    &--total {
      margin-top: 2px;
      border-top: 1px solid $grayLightColor;
      padding-top: 6px;
    }

    &--extra-promotion {
      #{$root}__small-text {
        flex-flow: column wrap;
      }
    }

    &--promotion {
      #{$root}__key {
        flex-flow: column wrap;
        gap: 0;
      }
    }

    &--shipping-bottom {
      display: none;
    }
  }

  &__small-text {
    font-size: 12px;
    display: block;
    font-weight: $fontWeightRegular;
    padding: 0 0 6px;
  }

  &__col-summary {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__key,
  &__value {
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    font-size: 14px;
    line-height: 1.4;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 16px;
    }

    &--bold {
      font-weight: $fontWeightBold;
    }

    #{$root}__row--sub-total & {
      font-weight: $fontWeightBold;
    }

    #{$root}__row--total & {
      font-weight: $fontWeightBold;
      font-size: 16px;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 20px;
      }
    }
  }

  &__key {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 6px;
    flex-grow: 2;

    #{$root}__row--extra-promotion & {
      flex-wrap: wrap;
      gap: 0;
    }
  }

  &__value {
    text-wrap: nowrap;
  }

  &__remove {
    color: $removeColor;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 8px 18px 10px;
    text-align: center;
    margin: 0 -4px 0 -17px;
    align-self: flex-start;

    @media (min-width: $screen-tablet-landscape) {
      margin: 0 -4px 0 -17px;
    }

    &:hover {
      text-decoration: none;

      @media (min-width: $screen-tablet-portrait) {
        color: $removeHoverColor;
      }
    }

    @include iconFont(delete) {
      font-size: 8px;
    }
  }

  &__additional-info {
    font-size: 14px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 16px;
    }
  }
}
