.product-box-basket {
  &__content {
    display: flex;
  }

  &__head {
    display: block;
    margin: 0 0 8px;
    line-height: 1.2;
    color: $textBaseColor;
    text-decoration: none;
  }

  &__img-container {
    margin: 0 16px 0 0;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $screen-mobile-large) {
      min-width: 50%;
    }
  }

  &__img-element {
    display: block;

    picture {
      display: flex;
    }
  }

  &__img {
    max-width: 100px;
    max-height: 200px;
    width: auto;
    margin: auto;
  }

  &__tags {
    text-align: right;
    flex: 1 1;
  }

  &__price {
    font-size: 25px;
    margin-bottom: 8px;
    width: 100%;
  }

  &__conversion-container {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  &__food-link {
    width: 100%;
    text-align: right;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 130px;
    margin-top: 8px;

    @media (min-width: $screen-mobile-landscape) {
      margin-top: 0;
    }

    @media (min-width: $screen-desktop) {
      width: 140px;
    }
  }

  .product-box {
    &__button-to-cart {
      width: 100%;
      max-width: none;
    }
  }

  .product-qty-and-cart-btn {
    width: 100%;
    flex-wrap: nowrap;
  }
}
