.checkout-step-summary-payment-express {
  margin-bottom: 16px;

  &__message {
    @include container();
  }

  &__form-box {
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: $screen-tablet-portrait) {
      width: 48%;
      margin-bottom: 0;
    }

    @media (min-width: $screen-laptop) {
      width: 30%;
    }
  }

  .checkout-step-summary__checkout-box {
    justify-content: space-between;
  }

  .checkout__box--payment {
    padding-bottom: 24px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 24px;
      margin-top: 16px;
    }

    @media (min-width: $screen-laptop) {
      padding-top: 0;
      margin-top: 0;
      width: 30%;
    }
  }

  &__birthdate {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 16px 0;
    margin: 16px 0;
    border: 0 none;
    position: relative;
    flex-direction: column;
    gap: 16px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: $grayLightColor;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &-title {
      font-size: 16px;
    }

    .date-input__icon {
      display: none;
    }
  }

  &__date-input {
    margin-bottom: 8px;
    width: 100%;
  }

  &__select {
    .select__selected-value {
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__submit-box {
    align-items: flex-start;
    display: flex;
    flex-flow: wrap column;
    justify-content: space-between;
  }

  &__birthday-message {
    padding-bottom: 8px;
    width: 100%;
    font-weight: $fontWeightBold;
  }

  &__date-input-container {
    padding: 0;
    width: 100%;
  }

  &__submit-button {
    width: 100%;

    .form__mandatory-text {
      display: block;
      margin: 4px 0 0;
    }
  }
}
