.basket-informative-benefits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  color: $formHeadlineColor;
  text-decoration: none;
  padding: 8px 0 12px;

  @media (min-width: $screen-tablet-portrait) {
    align-self: flex-end;
    width: 30%;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    &-image {
      margin: 0;
      order: 1;
    }

    &-text {
      margin: 0;
      order: 2;
    }

    img {
      max-height: 54px;
    }
  }
}
