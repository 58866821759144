.sticky-sidebar {
  $root: &;

  --sticky-top: 16px;

  &__container {
    @media (min-width: $screen-tablet-portrait-max) {
      position: sticky;
      top: var(--sticky-top);
      transition: top .3s;
    }

    #{$root}--fixed & {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 20;
      box-shadow: 0 2px 8px rgb(0 0 0 / .5);
    }
  }
}
