.hermes-form {
  &--inline-iframe {
    max-width: 510px;
    padding: 10px 20px;
  }

  &__text {
    padding: 10px 0 0;
  }
}
