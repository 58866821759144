.account-recurring-order-item {
  $root: &;
  border: $accountRecurringOrderItemBorder;
  padding: $accountRecurringOrderItemBoxPadding;
  background: $accountRecurringOrderItemBoxBackground;
  margin-bottom: $accountRecurringOrderItemBoxPadding;

  &__tile {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin: -8px;

    @media (min-width: $screen-mobile-landscape) {
      flex-direction: row;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding: 8px;
    order: 3;

    @media (min-width: $screen-tablet-sm) {
      width: 50%;
      min-width: 300px;
      margin: 0 0 0 auto;
    }

    @media (min-width: $screen-desktop) {
      width: 34%;
      margin: 0;
    }

    &-top {
      display: flex;
      flex-direction: column;
    }
  }

  &__info {
    padding: 8px;
    order: 1;
    width: 100%;

    @media (min-width: $screen-desktop) {
      width: 32%;
      order: 2;
    }
  }

  &__img {
    padding: 8px;
    width: 100%;
    margin: 0 auto;
    order: 2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $screen-tablet-sm) {
      width: 40%;
    }

    @media (min-width: $screen-desktop) {
      width: 32%;
      margin: 0;
    }

    .image-element {
      display: inline-block;
    }

    img {
      width: 100%;
      max-width: 270px;
    }
  }

  &__button {
    margin-bottom: 4px;

    &--disabled {
      display: flex;
      justify-content: center;
      padding: 6px 0 0;

      > span {
        padding: 0 6px;
      }
    }

    &--items {
      margin: 0;

      @include iconFont($accountRecurringOrderItemAfterIcon, 'after');

      @media (min-width: $screen-tablet-portrait) {
        margin: 24px 0 0;
      }

      &::after,
      &:hover::after {
        font-size: $accountRecurringOrderItemAfterIconFontSize;
        transform: $accountRecurringOrderItemAfterIconTransform;
      }

      #{$root}--open &::after {
        transform: $accountRecurringOrderItemAfterIconTransformOpen;
      }
    }

    &--download {
      margin-bottom: -8px;
      text-decoration: none;

      @include iconFont('download', before) {
        margin: 0 2px 0 0;
        position: relative;
        top: 2px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &--quit {
      order: 4;
      padding: 6px 0 0;
      line-height: 1.4;
      font-size: 14px;
    }
  }

  &__name {
    @include fontSize($baseFontSize);
    font-weight: $fontWeightBold;
    margin-bottom: 8px;
  }

  &__delivery-info {
    @include fontSize($fontSizeSmall);
    color: $grayColor;
    padding: 0 0 10px;
  }

  &__items {
    margin: 24px -12px -12px;
    background: $accountRecurringOrderItemBoxItemsBackground;

    @media (min-width: $screen-desktop) {
      margin: 24px -12px -12px;
    }
  }

  .products-list__element--type_product {
    padding: $accountRecurringOrderItemPaddingItems;
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
account-recurring-order-item {
  @include componentPreloader();
  display: block;
  width: 100%;
  min-height: 200px;
  margin-bottom: 16px;
}
