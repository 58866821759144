@import '../../../bps/components/cartPage/cartPage.scss';

.cart-page {
  $root: &;

  &__headline {
    @include fontSize($fontSizeXXXLarge);
  }

  &__content--wide {
    background: $whiteColor;
  }

  &__footer__wrapper {
    background-color: $whiteColor;
    padding-bottom: 8px;
  }

  &--empty {
    #{$root}__content {
      background: $whiteColor;
      padding-top: 24px;
      padding-bottom: 24px;
      max-width: none;
      // stylelint-disable-next-line
      grid-template-areas:
        "main main"
        "sidebar sidebar"
        "content content";
    }

    #{$root}__content-inner {
      @include container();
      text-align: center;
    }

    #{$root}__footer__container {
      background: $whiteColor;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__empty {
    color: $greenDarkColor;
    margin: 0 0 16px;
  }

  &__back-to-home-button {
    margin: 40px 0 0;
  }

  &__footer-button-col > .button {
    height: 46px;
  }
}
