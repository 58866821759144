@import '../../../bps/components/orderSummary/orderSummary.scss';

.order-summary {
  $root: &;

  &__shipping-info {
    &--not-free {
      color: $textBaseColor;

      &.order-summary__row {
        padding: 3px 10.7% 18px 0 !important;
      }

      strong {
        background: $redDarkColor;
        font-weight: $fontWeightMedium;
        color: $whiteColor;
        padding: 2px 4px;
        white-space: nowrap;

        @media (min-width: $screen-tablet-portrait) {
          padding: 4px 6px;
        }
      }

      & > * {
        width: 100% !important;
      }
    }
  }

  &__value {
    font-weight: $fontWeightBold;

    &--total {
      @include fontSize($fontSizeXXLarge);
      white-space: nowrap;
    }
  }

  .delivery-forecast-message-list {
    font-weight: $fontWeightMedium;
  }

  &__basket-content {
    padding: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 5px 0 0;
    }
  }

  &__row-summary--with-basket-content {
    #{$root}__col-summary-left {
      @media (min-width: $screen-tablet-landscape) {
        width: 60%;
      }
    }

    #{$root}__col-summary-right {
      @media (min-width: $screen-tablet-landscape) {
        width: 40%;
      }

      .order-summary__row {
        width: 100%;

        @media (min-width: $screen-tablet-portrait) {
          padding: 3px 12% 3px 0;
        }

        @media (min-width: $screen-tablet-landscape) {
          padding: 3px 18.7% 3px 0;
        }
      }
    }
  }

  &__additional-info {
    color: $greenDarkColor;
  }
}
