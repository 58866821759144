.account-recurring-orders-list {
  $root: &;

  &__headline {
    margin: 0 0 21px;
  }

  &__next-orders {
    margin-bottom: 40px;

    @media (min-width: $screen-tablet-landscape) {
      margin-bottom: 64px;
    }
  }

  &__no-orders {
    margin: 0 0 32px;
    display: inline-block;
  }

  &__history-orders {
    // hide all elements that have position >= 11
    &:not(&--show-all) > *:nth-child(n+11) {
      display: none;
    }
  }

  &__show-more {
    font-size: $fontSizeSmall;
    color: $textBaseColor;
    margin-top: 16px;
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;

    #{$root}__history-orders--show-all + & {
      display: none;
    }
  }
}
