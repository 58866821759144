.shipping-address-form {
  margin: 0 0 24px;

  &__address-option-label {
    margin-left: 12px;
    width: 100%;
  }

  &__address-option-head {
    margin-bottom: 12px;
    font-weight: $fontWeightSemiBold;
  }

  &__button {
    width: 100%;
    margin-top: 16px;

    &.button--cancel {
      margin-top: 8px;
      text-align: center !important;
    }
  }

  .radio-input:has(input:disabled) .address-box { // stylelint-disable-line plugin/no-unsupported-browser-features
    color: $grayColor;
  }
}
