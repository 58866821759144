.checkout-step-next-button {
  width: auto;
  min-width: 240px;

  @media (min-width: $screen-tablet-landscape) {
    width: 100%;
  }

  &--buy-now {
    max-width: none;

    @media (min-width: $screen-laptop) {
      min-width: 240px;
    }

    @media (min-width: $screen-desktop) {
      min-width: 365px;
    }
  }

  &.button--secondary {
    margin: 10px 0 0;
  }
}
