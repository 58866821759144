.checkout {
  &__box {
    margin: 0 0 24px;
    border-bottom: $checkoutBoxBorderBottom;
    padding-bottom: 24px;

    &--no-border {
      border: 0;
      padding: 0;
    }

    > .html-block {
      font-size: $checkoutBoxHtmlFontSize;
    }
  }

  &__box-header {
    font-size: $checkoutBoxHeaderFontSize;
    font-weight: $checkoutBoxHeaderFontWeight;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    line-height: 130%;

    &--sidebar {
      font-weight: $checkoutBoxHeaderSidebarFontWeight;
    }
  }

  &__box-content {
    font-size: $checkoutBoxContentFontSize;
    color: $grayDarkColor;
    line-height: 150%;

    &--pulse {
      animation: pulse;
      animation-duration: 2s;
      animation-delay: 1s;
    }
  }

  &__box-text {
    padding: 0 0 15px;

    &[href] {
      color: $textBaseColor;
    }
  }

  &__box-icon {
    display: inline-block;
    position: relative;

    > span {
      display: inline-block;
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &__summary {
    width: 100%;
    padding: 10px 10px 20px;
    border-radius: 4px;
    background: $whiteColor;

    &--fixed {
      @media (max-width: $screen-mobile-landscape-max) {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__box-sprite {
    &--PAY_PAL {
      @extend .sprite-paypal;
    }

    &--PAY_PAL_EXPRESS {
      @extend .sprite-paypalexpress;
    }

    &--CREDITCARD {
      @extend .sprite-creditcard;
    }

    &--DIRECT_DEBIT {
      @extend .sprite-sepa;
    }

    &--BANK_TRANSFER {
      @extend .sprite-rechnung;
    }

    &--GIFT_VOUCHER {
      @extend .sprite-giftvoucher;
    }

    &--MILES_MORE {
      @extend .sprite-milesmorecheckout !optional;
    }

    &--APPLE_PAY {
      @extend .sprite-apple-pay !optional;
    }

    &--APPLE_PAY_EXPRESS {
      @extend .sprite-apple-pay !optional;
    }
  }

  &__box-button {
    color: $checkoutBoxButtonColor;
    text-decoration: underline;
    font-size: $checkoutBoxButtonFontSize;
    font-weight: $checkoutBoxButtonFontWeight;
  }

  &__no-padding-bottom {
    padding-bottom: 0 !important;
  }

  &__text {
    font-size: $checkoutTextFontSize;
    color: $grayDarkColor;
    font-weight: $fontWeightRegular;
    line-height: $fontLineHeightRegular;

    p {
      padding: 0 0 20px;
      margin: 0;

      &:last-child {
        padding: 0;
      }
    }

    a {
      color: $grayDarkColor;
      font-weight: $fontWeightRegular;
      text-decoration: none;

      &:visited {
        color: $grayDarkColor;
      }

      &:hover {
        color: inherit;
        text-decoration: underline;
        text-decoration-color: $brandHighlightColor;
      }
    }
  }

  &__product-list {
    margin: 25px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }

  &__row {
    padding-bottom: 15px;

    &--border-top {
      border-top: 1px solid $grayLightColor;
      padding-top: 25px;
    }

    &--border-bottom {
      border-bottom: 1px solid $grayLightColor;
      padding-bottom: 25px;
    }
  }

  &__footer-button {
    margin: 15px 0 0;
    order: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }

  &__back-button {
    width: 100%;
    order: 2;
    margin-top: 20px;
    align-self: flex-start;

    @media (min-width: $screen-tablet-landscape) {
      width: auto;
      order: 1;
      margin-top: 0;
    }
  }

  &__footer-button-col {
    width: 100%;
    order: 1;
    margin-top: 20px;

    @media (min-width: $screen-tablet-landscape) {
      width: auto;
      order: 2;
      margin-top: 0;
    }

    > .button {
      width: 100%;
    }
  }

  &__sidebar {
    @media (min-width: $screen-tablet-portrait) {
      margin-left: auto;
      max-width: 365px;
      position: sticky;
      top: 20px;
    }

    .checkout-step-next-button {
      width: 100%;
    }
  }

  .button {
    &--paypal-checkout {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $paypalcheckout;

      i {
        margin: 1px 0 0;
      }
    }
  }
}
