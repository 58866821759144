@import "../../../bps/components/productOrderFreeItem/productOrderFreeItem.scss";

.product-order-free-item {
  &__add-to-basket-button {
    &--selected,
    &--selected:hover {
      border: 1px solid $productOrderFreeItemButtonSelectedColor;

      @include iconFont(checkSmall, after) {
        color: $productOrderFreeItemButtonSelectedColor;
        font-size: 9px;
        padding: 0 0 0 16px;
        line-height: inherit;
        transform: none;
      }
    }

    &--selected:hover {
      box-shadow: 0 0 0 1px $productOrderFreeItemButtonSelectedColor inset;
    }
  }

  &__product {
    box-shadow: 0 2px 7px 0 #979797;
  }

  .button--disabled {
    border: 1px solid #ccc;

    &:hover {
      box-shadow: 0 0 0 1px #ccc inset;
    }
  }
}
