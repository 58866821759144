.delivery-forecast-message {
  display: flex;
  font-size: $deliveryForecastMessageFontSizeMobile;
  line-height: 1.4;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $deliveryForecastMessageFontSizeDesktop;
  }

  &--block {
    padding: 10px 16px;
    border-radius: 4px;
    background: $productsOrderTableBackgroundColor;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;

    @media (min-width: $screen-desktop) {
      padding: 10px 40px;
    }
  }

  &__delivery-number {
    font-weight: $fontWeightBold;
  }

  &__delivery-option {
    font-weight: $deliveryForecastMessageDeliveryOptionFontWeight;
  }

  &__text-wrapper {
    display: inline-block;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }

  &__icon {
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 60px;
    }

    .icon--confirmationBox {
      min-height: 32px;

      @include iconFont(confirmationVersand) {
        font-size: 26px;
      }
    }
  }

  &__carrier {
    color: $textDarkestColor;
  }

  &__extra-shipping {
    @media (min-width: $screen-tablet-landscape) {
      padding: 0;
    }
  }
}
