.checkout-sidebar {
  &__box {
    border: $borderPrimaryColor 1px solid;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;

    .address-box {
      margin-bottom: 10px;
    }
  }

  &__box-header {
    font-weight: $fontWeightSemiBold;
    margin-bottom: 10px;
  }

  &__summary {
    padding: 5px 15px;

    &--left {
      font-size: $fontSizeMedium;
      text-align: left;
    }

    &--right {
      font-size: $fontSizeXXMedium;
      text-align: right;
    }

    &--bold {
      font-weight: $fontWeightBold;
    }
  }
}
