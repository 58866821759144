.basket-cross-selling {
  margin: 20px 0 0;

  &:empty {
    display: none;
  }

  .cart-page--empty & {
    margin: 10px 0 0;
  }

  &__header {
    font-size: $fontSizeMedium;
    text-align: center;
    font-weight: $fontWeightBold;
    margin-bottom: 8px;
  }

  &__product-box {
    border: $basketCrossSellingBorder;
    background: $basketCrossSellingBg;
    border-radius: $basketCrossSellingBorderRadius;
    padding: 8px 16px;
    width: 100%;
    max-width: 450px;

    @media screen and (min-width: $screen-laptop) {
      width: auto;
      max-width: none;
    }
  }

  .price-info {
    &__old {
      margin: 0;
    }

    &__current {
      .price-info__value {
        align-self: flex-end;

        &-euro {
          align-self: flex-end;
        }

        &-cent {
          align-self: flex-start;
        }
      }
    }
  }

  &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

    @media screen and (min-width: $screen-laptop) {
      display: block;
    }
  }
}
