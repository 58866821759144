.payment-method-form {
  $root: &;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &__debit-row {
    max-width: 400px;
  }

  &__debit-button-row {
    display: flex;
    justify-content: space-between;

    .button--cancel {
      line-height: 25px;
    }
  }

  &__billing-text {
    margin: 0 4px 0 33px;
    max-width: 550px;

    .checkbox__box {
      left: 0;
      top: 2px;
      transform: none;
    }

    .checkbox__label {
      font-size: 12px;
      color: $grayDarkColor;
      padding: 0 10px 20px;
    }

    .checkbox__input:checked + .checkbox__box + .checkbox__label {
      color: $grayDarkColor;
    }

    .form__text {
      margin-top: 0;
    }

    a {
      text-decoration: none;
      font-weight: $fontWeightBold;
    }
  }

  &__billing-text-info {
    margin: 4px 0 8px;
    font-size: 10px;
    line-height: 1.3;
    color: $grayDarkColor;
  }

  &__direct-debit-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    #{$root}__delete,
    #{$root}__edit {
      font-size: $paymentMethodFormEditFontSize;
      font-weight: $fontWeightSemiBold;
      text-decoration: underline;
      text-transform: $paymentMethodFormEditTextTransform;
      position: relative;
      top: -1px;
      cursor: pointer;
      margin: auto 0;
      bottom: 0;
      padding: 12px;

      @media (min-width: $screen-tablet-landscape) {
        top: 0;
        bottom: auto;
        margin: 0;
      }
    }

    #{$root}__delete {
      padding-right: 0;
    }

    #{$root}__direct-debit-edit-form {
      width: 100%;
      padding: 0 0 0 32px;
    }

    label.radio-input {
      width: 100%;
      margin: 0 4px;

      @media (min-width: $screen-tablet-portrait) {
        width: auto;
      }

      &--hidden-radio {
        .radio-input__radio {
          visibility: hidden;
        }
      }
    }

    .radio-input__label {
      width: 100% !important;
    }
  }

  &__edit-buttons {
    padding: 0 0 0 22px;
    margin-top: -8px;
    display: flex;

    .side-panel & {
      @media (min-width: $screen-mobile-landscape) {
        padding: 0;
      }
    }
  }

  &__option-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px;

    @media screen and (min-width: $screen-tablet-portrait) {
      margin: 4px 15px;
    }

    @media (min-width: $screen-tablet-landscape) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__creditcard {
    &-box {
      margin: 0 10px 0 16px;
      border-top: 1px solid #d3d3d3;

      @media screen and (min-width: $screen-tablet-portrait) {
        margin: 0 20px 0 32px;
        padding: 0;
      }

      .creditcard__expire-inputs {
        .form__col-5,
        .form__col-7 {
          width: 50% !important;
        }
      }
    }
  }

  .form__radio-line {
    #{$root}__creditcard-box {
      .form__group__inner-form {
        padding-left: 0;

        @media screen and (min-width: $screen-tablet-portrait) {
          padding: 10px 0;
        }
      }
    }

    #{$root}__direct-debit {
      &-box {
        margin: 0 10px 0 16px;

        @media screen and (min-width: $screen-tablet-portrait) {
          margin: 0 20px 0 32px;
          padding: 0;
        }

        .form__group__inner-form {
          padding: 10px 0;
        }
      }
    }
  }

  &__creditcard-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $paymentMethodCCOptionFontSize;

    #{$root}__option-name {
      display: flex;
      gap: 0;
      flex-direction: column;

      @media (min-width: $screen-mobile) {
        flex-direction: row;
        gap: 8px;
      }

      @media (min-width: $screen-laptop) {
        padding: 0 32px 0 0;

        .side-panel & {
          padding: 0;
        }
      }
    }

    #{$root}__expires-at--colored {
      color: $redColor;
    }

    > [class^='sprite-'] {
      min-width: 52px;
    }
  }

  &__creditcard-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;

    @media screen and (min-width: $screen-tablet-portrait) {
      flex-direction: row;
    }

    #{$root}__delete {
      font-size: $paymentMethodFormEditFontSize;
      font-weight: $fontWeightSemiBold;
      text-decoration: underline;
      text-transform: $paymentMethodFormEditTextTransform;
      position: relative;
      top: 3px;
      cursor: pointer;
      margin: auto 0;
      bottom: 0;

      @media (min-width: $screen-tablet-landscape) {
        bottom: auto;
        margin: 0;
      }
    }

    .creditcard__expires-delete {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (min-width: $screen-tablet-portrait) {
        width: 50%;
      }
    }

    label.radio-input {
      &--hidden-radio {
        .radio-input__radio {
          visibility: hidden;
        }
      }
    }

    #{$root}__expires-at {
      padding-left: 32px;

      @media screen and (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        padding-right: 8px;
        font-size: 14px;
      }
    }
  }

  .radio-input {
    margin: 0 0 8px;
    width: 100%;
    float: none;
    flex-wrap: wrap;

    &__sprite-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > [class^='sprite-'] {
        order: 2;
        margin: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
      }
    }

    &__sprite-text {
      display: inline-block;
      font-weight: $fontWeightBold;
    }

    &__sprite-text-sub {
      color: $grayColor;
      font-weight: $fontWeightRegular;
    }
  }

  .radio-input__label {
    padding: 0 14px;
    font-weight: $paymentMethodFormLabelFontWeight;

    &:has(:not(.form__group__inner-form)) { // stylelint-disable-line
      width: calc(100% - 24px);
    }
  }

  .radio-input__description {
    padding: 6px 0 0 33px;
    display: block;
  }

  .form__group__inner-form { // stylelint-disable-line
    padding: $paymentMethodFormLabelMargin;
    min-width: 80%;
    max-width: 100%;
    margin: 0;
    flex-wrap: nowrap;

    .radio-input {
      margin: 0;
      min-height: 50px;
      flex-wrap: nowrap;

      .side-panel & {
        max-width: 245px;
      }
    }

    .radio-input__label {
      .side-panel & {
        width: calc(100% - 14px);
        padding: 0 0 0 14px;

        > span {
          display: inline-block;
        }
      }
    }
  }

  .form__group--row-button {
    margin-top: 0;
  }

  .radio-input__input:checked ~ .radio-input__label {
    color: $paymentMethodFormLabelCheckedColor;
  }

  .form__option-area {
    padding: 8px 0 8px 8px;
  }

  .direct-debit {
    margin-right: 8px;
    padding-top: 16px;
    border-top: 1px solid #d3d3d3;

    @media screen and (min-width: $screen-tablet-portrait) {
      margin-right: 0;
    }

    &__sub-headline {
      & .radio-input__label {
        font-weight: $fontWeightBold;
      }
    }

    &__button-save {
      padding: 20px 32px;
      display: inline-flex;
      align-items: center;
      font-weight: $fontWeightBold;
    }
  }

  .creditcard {
    &__add {
      & .radio-input__label {
        font-weight: $fontWeightBold;
      }
    }

    &--type {
      font-weight: $fontWeightBold;
      margin: 16px 0 8px;
    }

    &__expire-inputs {
      .input__label {
        font-weight: $fontWeightBold;
      }
    }

    iframe {
      background: $paymentMethodFormIframeBackground;

      .user-area & {
        background: $paymentMethodFormIframeBackground;
      }
    }
  }

  #cardpan {
    input {
      background: red;
    }
  }
}
