.product-order-free-item {
  $root: &;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  border-bottom: 0;

  &__wrapper {
    margin: 0 0 24px;
    padding: 16px;
    background: $productOrderFreeItemBackgroundColor;
    border-radius: 4px;

    &--bg-img {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &--hide {
      display: none;
    }

    .checkout-step-summary & {
      display: none;
    }

    &--visible {
      display: block !important;
    }

    &--is-abo:not(#{$root}__wrapper--hide) {
      display: block;
    }
  }

  &__headline {
    color: $whiteColor;
    font-family: $fontPrimaryFamily;
    font-size: $fontSizeMedium;
    line-height: 1.2;
    font-weight: $fontWeightBold;
    text-align: center;
    margin: 0 0 16px;

    @media (min-width: $screen-tablet-xsm) {
      font-size: $fontSizeXMedium;
    }

    div {
      @include fontSize($productOrderFreeItemHeadlineSmallSize);
      display: block;
      line-height: 1.3;
      margin: 16px 32px;
      font-weight: $productOrderFreeItemHeadlineSmallWeight;
    }

    a {
      color: $whiteColor !important;
    }
  }

  &__product {
    background-color: $whiteColor;
    padding: 16px 0;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;

    @media (min-width: $screen-tablet-sm) {
      padding: 24px 0;
      min-height: 160px;
      width: calc(50% - 6px);
    }
  }

  &__container {
    display: block;
    overflow: hidden;

    @media (min-width: $screen-desktop) {
      display: flex;
    }

    &-center {
      width: 100%;
      z-index: 1;
      display: flex;
      gap: 12px;
      flex-flow: column;
      align-items: center;

      @media (min-width: $screen-tablet-xsm) {
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;
      }
    }
  }

  &__cell {
    @include fontSize($baseFontSize);
    padding: 0;

    &--image {
      width: 33%;
      order: 1;

      @media (min-width: $screen-tablet-landscape) {
        width: 40%;
      }
    }

    &--info {
      width: 66%;
      display: block;
      order: 2;
      flex-grow: 1;
      padding: 0 16px 0 0;

      @media (min-width: $screen-tablet-xsm) {
        padding: 0 24px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        width: 60%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
      }
    }

    &--button {
      margin: 16px 0 0;
    }

    &--disabled {
      color: $grayColor !important;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 110px;
    max-height: 110px;
    margin: auto;
    padding: 0 8px;

    @media (min-width: $screen-tablet-landscape) {
      padding: 0;
    }
  }

  &__name {
    color: $textDarkestColor;
    font-size: 16px;
    font-weight: $fontWeightBold;

    &:hover,
    &:visited {
      color: $textDarkestColor;
    }

    @media (min-width: $screen-tablet-landscape) {
      min-height: 90px;
    }
  }

  &__short-description {
    color: $productOrderFreeItemShortDescriptionColor;
    font-size: 14px;
    font-weight: $fontWeightThin;
    line-height: 1.4;
    display: block;

    &:hover,
    &:visited {
      color: $productOrderFreeItemShortDescriptionColor;
    }
  }

  &__description3 {
    display: $productOrderFreeItemDescription3Display;
    color: $textDarkestColor;
    font-size: 14px;
    font-weight: $fontWeightRegular;
    line-height: 1.2;
    padding: 6px 0 0;

    &:hover,
    &:visited {
      color: $textDarkestColor;
    }
  }

  &__add-to-basket-button {
    max-width: none;
    width: auto;
    padding: 0 16px;
    min-width: 164px;
    display: flex;
    white-space: inherit;
    justify-content: center;
    min-height: $buttonPrimaryHeightMobile;
    background: $productOrderFreeItemButtonColor;
    color: $productOrderFreeItemButtonBorderColor;
    border-color: $productOrderFreeItemButtonBorderColor;

    @media (min-width: $screen-tablet-xsm) {
      min-height: $buttonPrimaryHeightDesktop;
      min-width: 100%;
    }

    &::after,
    > span {
      align-self: center;
      line-height: 1.2;
    }

    &--selected,
    &--selected:hover {
      color: $productOrderFreeItemButtonSelectedColor;
      background-color: $productOrderFreeItemButtonSelectedBackgroundColor;
      border-color: $productOrderFreeItemButtonSelectedColor;
      line-height: $buttonPrimaryHeightMobile;
      font-weight: $fontWeightBold;

      @media (min-width: $screen-tablet-xsm) {
        line-height: $buttonPrimaryHeightDesktop;
      }

      @include iconFont(checkSmall, after) {
        color: $productOrderFreeItemButtonSelectedColor;
        font-size: 9px;
        padding: 0 0 0 16px;
        line-height: inherit;
        transform: none;
      }
    }
  }
}

.cart-page__order-summary > .product-order-free-item__hide-slot {
  display: none;
}
