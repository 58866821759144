.checkout-step-delivery {
  &__sub-headline {
    @include fontSize($checkoutStepDeliverySubHeadlineFontSize);
    margin: 0 0 16px;
    font-weight: $checkoutStepDeliverySubHeadlineFontWeight;
    display: block;
    text-align: center;

    @media (min-width: $screen-tablet-landscape) {
      text-align: left;
    }
  }

  &__error {
    margin-bottom: 25px;
    line-height: 1.4em;
  }

  &__section {
    margin-bottom: 32px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 48px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__side-bar {
    margin-top: 36px;
  }

  .payment-method-form {
    margin: 0;
  }
}
