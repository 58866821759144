.account-recurring-order-address {
  &__content {
    margin-bottom: 24px;

    .checkout-step-next-button {
      width: auto !important;
    }
  }

  &__buttons {
    justify-content: flex-start;
  }

  &__current-block {
    margin: 16px 0 32px;
  }

  &__current-head {
    display: block;
    font-size: $singleOrderDetailsFontSizeMedium;
    color: $brandSecondaryColor;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 10px;
  }

  &__current-address {
    font-size: $singleOrderDetailsFontSizeBase;
    color: $grayDarkColor;
    line-height: 1.3;

    @media (min-width: $screen-desktop) {
      font-size: $singleOrderDetailsFontSizeMedium;
    }
  }

  &__section-head {
    @include fontSize($fontSizeLarge);
    font-weight: $fontWeightRegular;
    margin: 8px 0 24px;
  }

  &__paragraph {
    margin: 16px 0;
  }

  &__address-list {
    display: flex;
    flex-wrap: wrap;
    margin: -8px -8px 16px;

    .checkout-step-address {
      &__box {
        margin: 8px !important;
        width: 50%;
        display: flex;
        flex-direction: column;
      }

      &__box-content {
        flex-grow: 1;
      }
    }
  }

  .address-form {
    margin: 10px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      padding: 20px 0 0;
    }

    .radio-input__row > .form__col-12 {
      padding: 0 0 10px;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0 5px 10px;
      }
    }
  }
}
