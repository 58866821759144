.checkout-process-breadcrumbs {
  overflow: hidden;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    border: 1px solid $borderDefaultColor;
  }

  &__breadcrumb {
    display: none;
    color: $textGrayColor;
    text-decoration: none;
    text-align: center;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      padding: 10px 5px 10px 35px;
      font-size: $baseFontSize;
      position: relative;
      background: $backgroundGrayColor;

      &::before {
        content: '';
        width: 35px;
        height: 35px;
        position: absolute;
        background: $backgroundGrayColor;
        border: 1px solid $borderDefaultColor;
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 30px;
        right: 0;
        top: 0;
        background: $backgroundGrayColor;
      }

      &:first-child {
        padding-left: 25px !important;
      }

      &:last-child {
        padding-right: 25px !important;
      }
    }

    &:visited {
      color: $textBaseColor;
      text-decoration: none;
    }

    &--ready {
      @media (min-width: $screen-tablet-portrait) {
        background: $backgroundWhiteColor;
        color: $textBaseColor;

        &::before {
          background: $backgroundWhiteColor;
        }

        &::after {
          background: $backgroundWhiteColor;
        }
      }
    }

    &--previous {
      display: block;
      font-size: $fontSizeSmall;
      padding: 5px;
      border-top: 1px solid $borderDefaultColor;
      border-left: 1px solid $borderDefaultColor;
      border-right: 1px solid $borderDefaultColor;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      @include iconFont(arrowLeft) {
        font-size: 10px;
      }

      @media (min-width: $screen-tablet-portrait) {
        padding: 10px 5px 10px 35px;
        border: none;
        font-size: $baseFontSize;

        &::before {
          content: '';
        }
      }
    }

    &--current {
      display: block;
      font-size: $baseFontSize;
      background: $brandPrimaryColor;
      padding: 10px;
      color: $textLightColor;

      @media (min-width: $screen-tablet-portrait) {
        padding: 10px 5px 10px 35px;

        &::before {
          background: $brandPrimaryColor;
        }

        &::after {
          background: $brandPrimaryColor;
        }
      }
    }
    // padding: 10px;
    // text-align: center;

    // &::after {
    //   content: '';
    // }
  }

  &__breadcrumb-name {
    @media (min-width: $screen-tablet-landscape-sm) {
      display: block;
      position: relative;
      z-index: 3;
    }
  }
}
