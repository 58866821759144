.miles-and-more-header {
  @include container();
  margin-bottom: 16px;

  &__container {
    border-bottom: 8px solid $milesAndMoreColor;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__logo {
    margin: 16px 24px 16px 0;
    width: 135px;
  }

  &__text {
    color: $milesAndMoreColor;
    margin: 0 0 16px;
    line-height: 1.2;
  }
}
