.product-box-rating {
  display: flex;
  justify-content: stretch;

  &__body-top {
    display: flex;
    flex-direction: column;
    min-height: 410px;
  }

  &__status-info {
    font-size: 13px;
    background: $infoColor;
    color: $whiteColor;
    text-align: center;
    padding: 6px;
    margin: 0 0 10px;

    &--success {
      background: $successColor;
    }

    &--warning {
      background: $warningColor;
    }
  }

  &__bought-date {
    font-size: 13px;
    color: $grayColor;
    text-align: center;
    padding: 0 0 10px;
  }

  &__rating-message {
    font-size: 16px;
    color: $textDarkestColor;
    text-align: center;
    margin: 5px -15px 0;
    padding: 10px 10px 0;
    border-top: 5px solid $bodyBackgroundColor;
    flex-grow: 1;
  }

  &__ratings {
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin: 6px 0 2px;
  }

  &__rating-message-head {
    font-weight: $fontWeightBold;
  }

  &__rating-headline {
    font-size: 18px;
    font-weight: $fontWeightSemiBold;
    margin: 10px 0 6px;
  }

  &__rating-vintage {
    font-size: 14px;
    margin: 6px 0;
  }

  &__rating-text {
    font-size: 16px;
    line-height: 1.4;
    position: relative;
  }

  .product-box__personalization-banner {
    display: none;
  }
}
