.checkout-step-address {
  $root: &;

  &--complete {
    #{$root}__side-bar {
      margin-top: 30px;
    }
  }

  &__address {
    .address-box__logo {
      top: auto;
      bottom: 0;
    }
  }

  &__section-title {
    font-size: 18px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 20px;
    }

    em, strong {
      font-weight: $fontWeightBold;
      font-style: normal;
    }
  }

  &__form-head {
    font-size: $fontSizeBase;
    font-weight: $fontWeightBold;
    margin: 0 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXMedium;
      margin: 0 0 24px;
    }

  }
}
