.delivery-option-form {
  font-size: 12px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 14px;
  }

  &__price {
    display: $deliveryOptionFormPriceDisplay;

    .delivery-option-form__option--EXPRESS & {
      display: none;
    }
  }

  &__text-paragraph {
    font-size: $fontSizeSmall;
    position: relative;
    padding: 16px 0 6px;
  }

  .form__radio-sub-element {
    max-width: 335px;
    display: flex;

    .delivery-option-form__price {
      display: block;
      order: 2;
      position: relative;
      top: 14px;
      left: 8px;
      font-weight: $deliveryOptionFormLabelFontWeight;
    }

    .date-input,
    .select {
      flex-grow: 2;
    }
  }

  &__forecast-wrapper {
    margin-bottom: 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 24px;
    }
  }

  &__combine-wrapper {
    margin-bottom: 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 24px;
    }

    .checkbox--switch .checkbox__label {
      font-size: 14px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 16px;
      }
    }
  }

  &__forecast {
    .delivery-forecast-message-list__item + .delivery-forecast-message-list__item {
      margin-top: 4px;
    }

    .delivery-forecast-message {
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;

      &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
      }

      &__icon {
        float: none;
        order: 2;
      }

      &__text {
        display: flex;
        gap: 2px;
        flex-direction: column;
        font-size: $deliveryForecastMessageFontSizeMobile;

        @media (min-width: $screen-tablet-portrait) {
          display: inline;
          font-size: $deliveryForecastMessageFontSizeDesktop;
        }
      }
    }
  }

  &__sub-element {
    padding: 0 0 0 32px;

    .form__radio-sub-element {
      padding: 10px 10px 0 0;
    }
  }

  &__option {
    &--disabled {
      display: none;
    }
  }

  &__notice {
    line-height: 1.2;
    margin: 0 0 16px;
    padding: 16px;
    color: $whiteColor;
    background-color: $infoColor;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;

    @media (min-width: $screen-desktop) {
      font-size: 16px;
    }
  }

  .radio-input {
    width: 100%;
    float: none;
    flex-wrap: nowrap;
    align-items: flex-start;

    &__description {
      display: block;
      font-size: $fontSizeXSmall;
      line-height: 1.2;
      margin: 1px 0;

      @media (min-width: $screen-desktop) {
        font-size: $fontSizeSmall;
        margin: 2px 0;
      }
    }

    &__label {
      padding-left: 14px;
      padding-right: 8px;
      font-weight: $deliveryOptionFormLabelFontWeight;
    }

    &__sprite-text-sub {
      @media (max-width: $screen-tablet-portrait) {
        display: block;
      }
    }

    &__info {
      margin: 2px 0 0;

      @media (max-width: $screen-tablet-portrait) {
        vertical-align: top;
        top: 0;
      }
    }

    &__input:checked ~ .radio-input__label {
      color: $deliveryOptionFormLabelCheckedColor;
    }

    & + .form__radio-sub-element {
      padding-top: 0;
    }
  }

  .checkbox--switch {
    .radio-input__info {
      order: 3;
      margin: 0;
    }

    & + .delivery-forecast-message {
      padding-left: 57px;
    }
  }
}
