.reset-password-page {
  $maxWidth: 700px;
  margin: 24px 0 0;

  &__container {
    @include container($maxWidth);
  }

  &__messages-slot {
    @include container();
    margin-bottom: 24px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 40px;
    }
  }

  &__headline {
    text-align: center;
    margin: 0 0 24px;
  }

  &__info {
    margin: 16px 0 24px;
    padding: 0;
    text-align: center;
    line-height: 1.2;
  }

  &__button {
    width: 100%;
  }
}
