.voucher-form {
  $root: &;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  background: $whiteColor;
  border: 1px solid $grayLightColor;
  border-radius: 4px;
  padding: 8px;
  margin: 16px 0;

  @media (min-width: $screen-tablet-landscape) {
    align-items: flex-start;
  }

  &__group {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 8px;
    opacity: 0;
    transition: opacity 300ms ease;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
  }

  &__headline {
    font-size: 16px;
    margin: 6px 20px 6px 6px;
    line-height: 1.4;
    position: relative;
    cursor: pointer;

    @include iconFont(arrowDown, after) {
      color: $textBaseColor;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%) scale(1, 1);
      transition: transform 300ms;
      font-size: 25px;
    }
  }

  &__form {
    padding: 0;
    width: 100%;
  }

  &--open {
    #{$root}__headline::after {
      transform: translateY(-50%) scale(1, -1);
    }

    #{$root}__group {
      opacity: 1;
      display: flex;
      height: auto;
      padding: 6px 0 0;
    }
  }

  &__code {
    padding: 0 0 8px;
    order: 1;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: calc(50% + 8px);
    }

    @media (min-width: $screen-tablet-landscape) {
      order: 2;
      width: 47%;
      padding: 16px 0 0;
    }
  }

  &__input {
    flex-grow: 1;

    .input__label--as-placeholder {
      position: absolute;
    }
  }

  &__submit-button {
    width: 100%;
    min-height: 48px;
  }

  .input__wrapper {
    background: $voucherFormInputBackground;
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }
  }
}
