.register-form {
  .slide-up-down__container,
  .form__element {
    width: 100%;
  }

  &__subheadline {
    font-size: $registerFormSubHeadlineFontSize;
    font-family: $fontHeadlineFamily;
    margin: $registerFormSubHeadlineMargin;
    font-weight: $formHeadlineFontWheight;
    color: $formHeadlineColor;
  }

  &__address-section {
    margin-left: 16px;
  }

  &__buttons {
    margin-bottom: 16px;
  }

  .hermes-widget {
    .lightbox {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      max-height: 95vh;
      top: 10px;
      width: calc(100% - 10px);
    }

    .lightbox__container {
      min-height: 70vh;
      overflow: visible;
    }

    .hermes-widget__iframe-wrapper {
      height: 100%;
    }
  }
}
