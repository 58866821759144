.checkout-page {
  &__container {
    @include container($maxWidthInner);
    display: flex;
    gap: 20px;
    flex-flow: column;

    @media (min-width: $screen-tablet-landscape) {
      @include container();
      flex-flow: row;
      flex-wrap: nowrap;
    }
  }

  &__main {
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      width: 64%;
    }
  }

  &__sidebar {
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      width: 36%;
    }
  }

  &__submit-btn {
    width: 100% !important;
    margin-top: 16px;
  }

  &__sidepanel-form {
    background: $sidePanelCheckoutFormBackground;
    padding: 0 8px;

    @media (min-width: $screen-mobile-large) {
      padding: 0 16px;
    }

    &--billing-form & {
      .button {
        text-align: center !important;
        width: 100%;
        margin: 0 10px;

        &--cancel {
          text-align: center !important;
          width: 100%;
        }
      }
    }

    > .form,
    .address-form.form,
    .shipping-address-form__address-book {
      position: relative;
    }

    > .form,
    .address-form.form,
    .shipping-address-form__address-book-list {
      margin-top: 24px;
      margin-bottom: 60px;
    }

    .checkout-page__sidepanel-head,
    .checkout-element__section-title,
    .checkout-step-address__form-head {
      font-size: 20px;
      font-weight: $fontWeightBold;
      font-family: $fontHeadlineFamily;
      margin: 0;
      padding: 20px 40px 0 0;
      max-height: 40px;
    }

    &--button-fixed {
      position: relative;

      .checkout-element__box {
        padding: 0;
        background: transparent;
      }

      .form__group__wrapper .form__col,
      .address-form__wrapper .address-form-inputs {
        background: $checkoutElementBoxBackground;
        border-radius: 4px;
        padding: 16px 12px 8px 12px;
        margin: 0;

        @media (min-width: $screen-mobile-landscape) {
          padding: 16px 14px 8px 14px;
        }
      }

      .shipping-address-form__address-book-list,
      .delivery-option-form__form-wrapper,
      .payment-method-form__form-wrapper,
      .address-form__wrapper,
      .form__group__wrapper {
        @include customScrollbar();
        overflow-y: auto;
        height: calc(100vh - 180px);
        overflow-x: hidden;
        padding-right: 10px;
        width: calc(100% + 10px);
      }

      .shipping-address-form__address-book .shipping-address-form__address-book-list,
      .form__group--hermes-widget,
      .payment-method-form__form-wrapper,
      .delivery-option-form__form-wrapper,
      .shipping-address-form__new .address-form__wrapper {
        @include customScrollbar();
        height: calc(100vh - 150px);
        padding-bottom: 80px;
        padding-right: 10px;
        width: calc(100% + 10px);
      }

      .form__group__wrapper {
        padding: 0 5px 20px;
      }

      .shipping-address-form__button-row,
      .form__group--action-bar {
        position: fixed;
        right: 0;
        background: $whiteColor;
        margin: 0;
        padding: 20px;
        width: 100%;
        max-width: $sidePanelCheckoutMaxWidth;
        bottom: 0;
        min-height: 86px;

        &::after {
          content: '';
          position: absolute;
          top: -6px;
          left: 0;
          width: 100%;
          height: 6px;
          background: linear-gradient(180deg, rgba(0 0 0 / 0) 0%, rgba(0 0 0 / .2) 100%);
        }

        .button {
          margin: 0;
          width: 100%;
          text-align: center !important;
        }

        .button--cancel {
          line-height: $buttonPrimaryHeightDesktop;
        }

        .form__mandatory-text {
          display: none;
        }
      }

      .delivery-option-form .button--cancel,
      .payment-method-form .button--cancel,
      .shipping-address-form__button.button--cancel {
        display: none;
      }

      .hermes-widget {
        .form__group.form__group--row {
          margin: 0;
        }

        .button--secondary {
          width: 100%;
        }

        .form__group--action-bar {
          position: relative;
          bottom: auto;
          margin: 10px 0 0;
          padding: 0 5px 10px;
          width: 100%;
        }
      }

      .shipping-address-form__new .button--cancel {
        display: none;
      }

      .payment-method-form {
        &__creditcard-option .payment-method-form__option-name {
          flex-direction: column;
          gap: 0;
        }

        .form.creditcard {
          width: calc(100% - 16px);
        }
      }
    }
  }
}

.checkout-page__main .checkout-page--preloader.checkout-element__box,
.checkout-page--preloader.checkout-element__box,
.checkout-step-delivery .placeholder__content,
.checkout-page--preloader {
  @include componentPreloader() {
    min-height: 140px;
    border-radius: $checkoutPagePreloaderBorderRadius;
  }
}

.checkout-page--preloader {
  &.checkout-page--preloader-small {
    min-height: 68px;
  }

  &.checkout-page--preloader-message {
    min-height: 38px;
  }
}
