@import '../../../bps/components/accountRecurringOrderAddress/accountRecurringOrderAddress.scss';

.account-recurring-order-address {
  &__buttons {
    justify-content: space-between;
    max-width: 860px;
    margin: -20px -10px 0;
  }

  &__section-head {
    font-size: $checkoutBoxHeaderFontSize;
    font-weight: $checkoutBoxHeaderFontWeight;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    line-height: 130%;
  }

  &__content {
    max-width: 840px;

    .checkout-step-address__box-header {
      margin: 8px 0 16px;
      width: 100%;
      flex-direction: row;
    }

    .checkout-step-address__box-content {
      border: 0 none;
      padding: 5px 0 20px;
      height: 100%;
    }

    .account-addresses__list {
      margin: 0;
    }

    .account-addresses__box {
      justify-content: flex-start;
      width: 100%;
      flex-direction: row;

      @media (min-width: $screen-tablet-landscape) {
        width: calc(50% - 10px);
        flex-direction: row;
        align-content: flex-start;

        &--primary-new {
          align-content: center;
        }
      }
    }
  }

  .checkout-step-next-button {
    width: auto;
  }
}
