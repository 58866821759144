@import '../../../bps/components/accountRecurringOrderItem/accountRecurringOrderItem.scss';

.account-recurring-order-item {
  $root: &;

  &__name,
  &__delivery-info {
    line-height: 1.2;
  }

  &__buttons {
    justify-content: space-between;
  }

  &__button {
    &--skip {
      order: 3;
      color: $textDarkestColor;

      &:hover {
        color: $textDarkestColor;
      }
    }

    &--items {
      order: 5;
    }

    &--download {
      &::before {
        font-weight: bold;
      }
    }
  }

  &__items {
    margin: 24px -21px -16px;
    padding: 6px 0 20px;
    background: $accountRecurringOrderItemBoxItemsBackground;

    @media (min-width: $screen-tablet-portrait) {
      margin: 24px -22px -17px;
    }

    @media (min-width: $screen-desktop) {
      margin: 24px -22px -17px;
    }
  }
}
