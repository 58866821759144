.checkout-step-summary {
  &__message {
    margin: 0 auto 16px;

    &:empty {
      margin: 0;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
  }

  &__address {
    .address-box__logo {
      top: auto;
      bottom: 0;
    }
  }

  &__side-bar {
    margin-top: 30px;
  }

  &__section {
    &--payment,
    &--delivery {
      .checkout-step-summary__icon {
        margin-right: 0;
      }

      .checkout-element__box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        @media (min-width: $screen-mobile-landscape) {
          padding-right: $checkoutStepSummaryBoxActionPaddingRight;
          flex-wrap: nowrap;
        }

        @media (min-width: $screen-tablet-portrait) {
          gap: 20px;
        }
      }

      .checkout-element__box-action {
        @media (min-width: $screen-mobile-landscape) {
          transform: none;
          top: 0;
          right: 4px;
          bottom: 0;
        }
      }
    }

    &--delivery {
      .icon {
        min-width: 51px;
      }

      .icon--confirmationBox {
        @include iconFont(confirmationVersand) {
          font-size: 26px;
        }
      }

      @media (max-width: $screen-mobile-landscape-max) {
        .checkout-step-summary__section-text {
          display: flex;
          font-size: 16px;
          flex-direction: column;
          gap: 6px;

          .checkout-step-summary__section-label {
            display: flex !important;
            height: 40px;
            align-items: center;
          }

          .products-order-table__forecast {
            margin: 0 0 0 -60px;
          }
        }

        .checkout-element__box {
          flex-wrap: nowrap;
          align-items: flex-start;
        }
      }
    }

    &--delivery-split {
      .checkout-element__box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        @media (min-width: $screen-tablet-portrait) {
          gap: 20px;
        }
      }
    }
  }

  &__section-text {
    display: inline;
    font-size: $checkoutStepSummaryFontSizeMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $checkoutStepSummaryFontSizeDesktop;
    }

    * {
      display: inline !important;
    }
  }

  .checkout-element__box,
  .delivery-forecast-message__date-text,
  .delivery-forecast-message__delivery-option {
    font-size: $checkoutStepSummaryFontSizeMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $checkoutStepSummaryFontSizeDesktop;
    }
  }

  &__payment-method-name {
    font-weight: $checkoutStepSummaryPaymentMethodNameFontWeight;
  }

  &__payment-user-name,
  .delivery-forecast-message__date-text {
    &::before {
      @media (min-width: $screen-tablet-portrait) {
        content: '\2013';
        font-size: 17px;
        margin: 0 8px 0 4px;
      }
    }
  }

  .products-order-table {
    &__group-head {
      .delivery-forecast-message__date-text {
        &::before {
          @media (min-width: $screen-tablet-portrait) {
            margin: 0 8px;
          }
        }
      }
    }

    &__group-headline {
      // same as .checkout-element__section-title
      font-size: $fontSizeMedium;
    }
  }

  .checkout-element__box--flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .delivery-forecast-message--block {
    padding: 0;
  }

  &__payment-user {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    flex-direction: column;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__payment-user-name {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }

  &__terms {
    font-size: $checkoutStepSummaryTermsFontSize;
    line-height: 1.2;
    margin: 5px 0;

    p {
      margin: 0;

      & + p {
        margin: 8px 0 0;
      }
    }

    a {
      color: $textBaseColor;
      text-decoration: underline;
    }
  }

  &__split-icon {
    background: url(../staticImages/delivery-split.svg) center no-repeat;
    background-size: contain;
    width: 51px;
    height: 36px;
    display: inline-block;
    vertical-align: middle;
  }

  &__split-label {
    flex: 1 1;
  }

  &__split-action {
    .checkbox__label {
      display: none;
    }
  }

  .delivery-forecast-message__date-text strong {
    @media (max-width: $screen-mobile-landscape) {
      display: inline-block;
    }
  }

  .sticky-sidebar--static {
    .checkout-element__box {
      padding: 10px;
      border-radius: 0;
      margin: 0 -10px;
      width: calc(100% + 20px);

      @media (min-width: $screen-mobile-sm) {
        padding: 20px 15px;
        margin: 0 -15px;
        width: calc(100% + 30px);
      }

      @media (min-width: $screen-tablet-portrait) {
        border-radius: 4px;
        margin: 0;
        width: 100%;
      }
    }
  }
}
