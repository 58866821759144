.header-checkout {
  position: relative;
  z-index: $indexHeader;

  .native-app & {
    z-index: $indexAppNav;
  }

  &__wrapper {
    box-shadow: $headerCheckoutBoxShadow;
    background: $headerCheckoutBackground;
  }

  &__main {
    @include container();
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: $headerCheckoutMobileMinHeight;

    @media (min-width: $screen-laptop) {
      justify-content: space-between;
    }

    @media (min-width: $screen-desktop-sm) {
      min-height: $headerCheckoutDesktopMinHeight;
    }
  }

  &__burger-menu-trigger {
    position: absolute;
    left: 20px;

    @media (min-width: $screen-laptop) {
      display: none;
    }
  }

  &__logo-wrapper {
    order: 1;
  }

  &__trust-wrapper {
    display: none;
    order: 2;

    @media (min-width: $screen-laptop) {
      display: block;
      text-align: right;
    }
  }

  &__logo-link {
    display: inline-block;
    width: $headerCheckoutMobileWidth;
    padding: $headerCheckoutPadding;
    box-sizing: content-box;

    @media (min-width: $screen-desktop-sm) {
      width: $headerCheckoutDesktopWidth;
    }
  }

  &__logo {
    width: 100%;
    height: auto;
    display: block;
  }

  &__checkout-process-breadcrumbs-wrapper {
    position: relative;
  }

  &__checkout-process-breadcrumbs {
    order: 6;
  }

  &__trust {
    @include fontSize($fontSizeXMedium);
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &::before {
      font-size: 130%;
      vertical-align: sub;
      margin-right: 5px;
    }

    &--delivery {
      @include iconFont(shipping);
    }

    &--secure {
      @include iconFont(security);
    }
  }

  &__sprite {
    display: flex;

    & > * {
      display: inline-block;
      text-transform: uppercase;
      margin: 0 15px;
      align-self: center;
    }
  }

  &__svg {
    display: flex;

    img {
      height: $headerCheckoutSvgMobileHeight;
      width: $headerCheckoutSvgMobileWidth;

      @media (min-width: $screen-desktop-sm) {
        height: $headerCheckoutSvgDesktopHeight;
        width: $headerCheckoutSvgDesktopWidth;
      }
    }
  }
}
