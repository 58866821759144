.favorite-wine-teaser {
  position: relative;
  background: $grayLighterColor;
  padding: 8px;
  border-radius: 4px;
  margin-top: 24px;

  @media (min-width: $screen-tablet-landscape) {
    padding: 16px 200px 16px 16px;
  }

  @media (min-width: $screen-desktop) {
    padding: 16px 220px 16px 16px;
  }

  &__head {
    @include fontSize($fontSizeXXMedium);
    font-weight: $fontWeightBold;
    color: $brandSecondaryColor;
  }

  &__text {
    @include fontSize($fontSizeSmall);
    margin: 12px 0;
    font-weight: $fontWeightMedium;
    line-height: 1.2;
  }

  &__select {
    margin: 8px auto 8px 0;
    max-width: 400px;
  }

  &__disclaimer {
    font-size: $fontSizeXXSmall;
  }

  &__img {
    display: none;

    @media (min-width: $screen-tablet-landscape) {
      display: block;
      position: absolute;
      top: 0;
      right: 8px;
      width: 180px;
    }
  }

  .select__custom-option {
    color: $textDarkestColor;

    &:hover {
      color: $whiteColor;
    }
  }

  .favorite-wine-teaser-select--disabled {
    .select {
      &__wrapper {
        background: $whiteColor;
      }

      &__label {
        color: $redColor;
      }
    }
  }
}
