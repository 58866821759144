.products-order-table {
  $root: &;

  &__wrapper {
    background-color: $checkoutPageBackground;
  }

  &__header {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $borderDefaultColor;
      padding: $productsOrderTableHeaderPadding;

      .user-area & {
        padding: 15px 0 0;
        border-bottom: 2px solid $borderDefaultColor;
      }
    }
  }

  &__header-label {
    padding: 0 3px 11px !important;
    font-size: 14px;
    font-weight: $productsOrderTableHeaderLabelFontWeight;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 19px;

      .user-area & {
        font-size: 16px;
      }
    }

    &--image {
      @extend .product-order-position__cell--image;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 10px;
      }
    }

    &--info {
      @extend .product-order-position__cell--info;
    }

    &--availability {
      @extend .product-order-position__cell--availability;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    &--price {
      @extend .product-order-position__cell--price;
    }

    &--quantity {
      @extend .product-order-position__cell--quantity;
    }

    &--remove {
      @extend .product-order-position__cell--remove;
    }

    &--summary {
      @extend .product-order-position__cell--summary;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &--reorder {
      @extend .product-order-position__cell--reorder;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }
    min-height: 125px;
  }

  &__cell-label {
    padding: 3px 0 6px;
    width: 100% !important;
    font-size: 10px !important;
    font-weight: $fontWeightBold;
    line-height: 14px;
    display: inline-block;
    color: $grayColor;
    text-transform: $productsOrderTableCellLabelTextTransform;
    letter-spacing: 1px;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__product {
    padding: 16px 8px;
    background: $productsOrderTableBackgroundColor;
    border-radius: 4px;
    margin-bottom: 6px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 16px 16px 16px 8px;
    }
  }

  &__forecast {
    margin: 0;
    flex: 1;

    .delivery-forecast-message {
      &__text-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  &__group {
    position: relative;
    margin: 0 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 36px;
    }

    &:last-child {
      margin: 0;
    }

    &--error {
      #{$root}__product {
        border: 1px solid $productOrderTableErrorBorderColor !important;
        margin: 6px 0 0;
      }
    }
  }

  &__group-head {
    display: flex;
    justify-content: flex-start;
    flex-flow: column wrap;
    align-items: flex-start;

    @media (min-width: $screen-tablet-portrait) {
      flex-wrap: nowrap;
    }
  }

  &__group-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: column;

    @media (min-width: $screen-mobile-landscape) {
      flex-flow: row wrap;
    }
  }

  &__group-middle {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    justify-content: space-between;
    flex-flow: column;
    gap: 4px;

    @media (min-width: $screen-tablet-portrait) {
      flex-flow: row wrap;
    }
  }

  &__group-bottom {
    padding: 12px;
    border-radius: 4px;
    background: $productsOrderTableBackgroundColor;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 6px;

    @media (min-width: $screen-mobile-landscape) {
      padding: 16px 12px 16px 16px;
      gap: 16px;
    }

    &--error {
      border: 1px solid $productOrderTableErrorBorderColor !important;

      .products-order-table__button {
        color: $productOrderTableErrorTextColor;

        &::before {
          color: $productOrderTableErrorTextColor;
        }
      }
    }

    .delivery-forecast-message__text {
      font-weight: $fontWeightRegular;
      color: $textDarkestColor;
    }

    .products-order-table__forecast,
    .delivery-forecast-message__delivery-option,
    .products-order-table__forecast .delivery-forecast-message__text-wrapper {
      @media (min-width: $screen-mobile-landscape) {
        display: inline;
      }
    }

    .delivery-forecast-message__text-group {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;

      @media (min-width: $screen-mobile-landscape) {
        display: inline-block;
        flex-grow: 2;
        gap: 0;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 150px;
      padding: 2px;
      min-width: 51px;
      margin-right: 12px;

      @media (min-width: $screen-mobile-landscape) {
        margin-right: 0;
      }

      &::before {
        font-size: 36px;
      }
    }

    .icon--confirmationBox {
      @include iconFont(confirmationVersand) {
        font-size: 26px;
      }
    }
  }

  &__group-headline {
    @include fontSize($fontSizeXMedium);
    font-weight: $fontWeightBold;
    color: $textBaseColor;
    margin-bottom: 8px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 12px;
    }
  }

  &__group-text {
    @include fontSize($fontSizeBase);
    font-weight: $fontWeightSemiBold;
    color: $textBaseColor;
    line-height: 1.2;
    margin: 0 0 6px;
  }

  &__group-error {
    @include fontSize($fontSizeBase);
    font-weight: $fontWeightBold;
    color: $productOrderTableErrorTextColor;
  }

  &__group-anchor {
    position: absolute;
    top: -125px;

    @media (min-width: $screen-tablet-portrait) {
      top: -175px;
    }

    @media (min-width: $screen-desktop) {
      top: -100px;
    }
  }

  &__group-link {
    a {
      text-align: right;
      color: $productOrderTableErrorTextColor;
    }
  }

  &__group-label {
    @include fontSize($fontSizeSmall);
    color: $whiteColor;
    background: $brandPrimaryColor;
    padding: 4px 16px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    margin-top: 16px;
  }

  &__button {
    @include iconFont('edit');
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    margin: -8px;

    span {
      display: none;
    }

    @media (min-width: $screen-mobile-landscape) {
      text-decoration: underline;
      margin: 0;

      span {
        display: block;
      }

      &::before {
        display: none;
      }
    }
  }
}
