@import '../../../bps/components/productsOrderTable/productsOrderTable.scss';

.products-order-table {
  $root: &;

  &__forecast {
    margin: 0;
  }

  &__product {
    &:first-child {
      @media (max-width: $screen-mobile-landscape-max) {
        &::before {
          content: "";
          width: 100%;
          margin-top: 0;
          padding-top: 0;
          border-top: 0 none;
        }
      }
    }

    &:last-child {
      border-radius: 4px 4px 10px 10px;
    }
  }

  &__products {
    > #{$root}__group:first-child {
      padding: 16px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0;
      }
    }
  }

  &__group-link,
  &__group-number,
  &__group-text {
    @include fontSize($fontSizeSmall);
    line-height: 1.4;
  }

  &__group-number {
    min-width: 70px;
  }

  &__group-bottom {
    border-radius: 10px 10px 4px 4px;
  }

  &__header {
    margin-bottom: 12px;
    border-bottom: 1px solid $blackColor;
  }

  &__group-link {
    flex-grow: 2;
    justify-content: flex-end;
    display: flex;

    a {
      color: $productOrderTableErrorTextColor;
    }
  }

  &__group-footer {
    display: flex;
    flex-wrap: wrap;
    top: -1px;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }

    &::after {
      content: "";
      background: $whiteColor;
      width: 100%;
      padding-bottom: 18px;
      border-bottom: $textDarkestColor 1px solid;
    }

    #{$root}__group-link {
      justify-content: flex-start;
      background: $productOrderTableErrorBackgroundColor;
      padding: 10px;
    }
  }

  &__group--error {
    #{$root}__group-text {
      color: $productOrderTableErrorTextColor;
    }

    #{$root}__product {
      @media (min-width: $screen-tablet-portrait) {
        border: 1px solid $productOrderTableErrorBorderColor !important;
        border-radius: 10px;
      }
    }
  }

  .delivery-forecast-message {
    @include fontSize($fontSizeSmall);
  }
}
