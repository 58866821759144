.account-review-form {
  margin: 10px 0 20px;

  @media (min-width: $screen-tablet-landscape) {
    display: flex;
    width: 100%;
  }

  &__headline {
    font-size: 20px;
    font-weight: $fontWeightBold;
    text-align: center;
    margin: 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 24px;
    }
  }

  &__form {
    background: $whiteColor;
    padding: 20px;

    @media (min-width: $screen-tablet-landscape) {
      width: 45%;
      margin-bottom: 8px;
    }

    @media (min-width: $screen-desktop) {
      width: 60%;
    }

    .ratings {
      font-size: 35px;
      padding: 0;
      margin: 0;

      .ratings__label {
        margin: 0 4px;
      }
    }

    .input__wrapper,
    .select__wrapper,
    .product-review-form__section-toggle {
      background: $directOrderTeaserInputBackgroundColor;
    }

    .product-review-form__section-toggle {
      border-bottom: 1px solid $textDarkestColor;
      margin: 0;
      padding: 15px 40px 15px 11px;
      font-weight: $fontWeightSemiBold;
      font-size: 14px;
    }

    .product-review-form__section {
      padding: 6px 0;
    }

    .form__text,
    .form__text a {
      font-size: 11px;
      color: $textDarkestColor;
      margin: 0;
    }

    .select__selected-value {
      padding-top: 0;
    }
  }

  &__section {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -6px;

    .form__group {
      margin-bottom: 12px;

      &--info {
        display: flex;
        justify-content: space-between;
      }
    }

    .product-review-form__input {
      width: 100%;
    }

    .product-review-form__textarea {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0 0 12px;

      .input__wrapper--textarea {
        height: 100%;
        min-height: 120px;
      }

      .input__wrapper--valid::after {
        top: 15px;
      }
    }
  }

  &__section-full {
    width: 100%;
    padding: 0 10px;
  }

  &__section-half {
    padding: 0 6px;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 100%;
    }

    @media (min-width: $screen-desktop) {
      width: 50%;
    }
  }

  &__buttons {
    margin: 12px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media (min-width: $screen-desktop) {
      margin: 17px 0 0;
    }
  }

  &__submit-button {
    align-self: flex-end;
  }

  &__disclaimer {
    padding: 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 20px 10px 0;
    }

    @media (min-width: $screen-desktop) {
      width: 60%;
      padding: 0 20px 0 0;
      align-self: center;
    }
  }

  &__slider {
    padding: 0;
    margin: 20px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 40px;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 55%;
      margin: 0;
      padding: 0 0 0 60px;
    }

    @media (min-width: $screen-desktop) {
      width: 40%;
    }

    .carousel__button--prev {
      left: -5px;

      @media (min-width: $screen-tablet-portrait) {
        left: -45px;
      }
    }

    .carousel__button--next {
      right: -5px;

      @media (min-width: $screen-tablet-portrait) {
        right: -45px;
      }
    }

    .carousel__page-info {
      margin: 2px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        display: block;
        width: 100%;
        margin: 10px 0 -20px;
        font-size: 16px;
        text-align: center;
      }
    }

    .product-box-rating__body-top {
      min-height: 428px;
    }
  }

  &__headline--center {
    text-align: center;
    line-height: 1.4;
    margin: 30px 0;
  }

  &__step {
    &--submit {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &--success {
      h3 {
        font-size: 24px;
        font-weight: $fontWeightSemiBold;
        color: $successBasketColor;
      }
    }

    &--error {
      h3 {
        font-size: 24px;
        font-weight: $fontWeightSemiBold;
        color: $errorColor;
      }
    }
  }
}
