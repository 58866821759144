@mixin checkoutLayout() {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  &__submit-button {
    @media (min-width: $screen-tablet-portrait) {
      min-width: 365px;
      width: auto;
    }

    &--top {
      margin: 25px 0 15px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0;
      }
    }

    &--bottom {
      width: 100%;
      order: 1;

      @media (min-width: $screen-tablet-portrait) {
        width: auto;
        order: 2;
      }
    }
  }

  &__back-button {
    width: 100%;
    order: 2;
    margin-top: 20px;
    align-self: flex-start;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      order: 1;
      margin-top: 0;
    }
  }
}
