.product-order-position {
  $root: &;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  @media (min-width: $screen-desktop-sm) {
    &:hover {
      #{$root}__variations--editable {
        border: 1px solid $textBaseColor;
        margin: 5px 0;
        padding: 5px 35px 5px 5px;
      }

      #{$root}__edit-button--desktop {
        display: block;
      }
    }
  }

  &__cell {
    @include fontSize($baseFontSize);

    @media (min-width: $screen-tablet-xsm) {
      padding: 0 10px;
    }

    &--image {
      width: 28%;
      padding: 0;
      order: 1;
      margin: 0 0 10px;

      @media (min-width: $screen-tablet-xsm) {
        width: 9%;
        padding: 0 5px;
        margin: 0;
      }

      img {
        max-height: 95px;
        max-width: 100%;
        margin: auto;
        width: auto;
        position: relative;
      }
    }

    &--info {
      width: 55%;
      display: block;
      margin-bottom: 5px;
      order: 2;

      @media (min-width: $screen-tablet-xsm) {
        width: 32%;
        margin-bottom: 0;
        padding: 0 10px 0 0;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 26%;
      }
    }

    &--availability {
      width: 100%;
      display: none;
      margin-bottom: 15px;
      order: 4;

      @media (min-width: $screen-tablet-xsm) {
        width: 16%;
        margin-bottom: 0;
        order: 3;
        padding: 0;
      }

      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }
    }

    &--availability-mobile {
      display: block;
      margin: 4px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &--price {
      width: 30%;
      order: 5;
      align-self: flex-start;
      text-align: right;

      @media (min-width: $screen-tablet-xsm) {
        align-self: center;
        order: 4;
        width: 18%;
        padding-left: 10px;
        padding-right: 0 !important;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 12%;
        padding-right: 10px !important;
      }
    }

    &--quantity {
      width: 28%;
      order: 4;
      text-align: center;
      align-self: flex-start;
      position: relative;

      @media (min-width: $screen-tablet-xsm) {
        text-align: left;
        align-self: center;
        width: 13%;
        order: 4;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 9%;
      }

      &.cart-quantity-edit {
        padding: 0 0 0 10px;
      }
    }

    &--remove {
      padding: 0;
      order: 3;
      width: 6%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: flex-start;
      position: relative;

      @media (min-width: $screen-tablet-xsm) {
        order: 6;
        width: 3%;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 2.5%;
        align-self: center;
      }

      @media (min-width: $screen-tablet-landscape) {
        width: 5%;
      }
    }

    &--summary {
      width: 30%;
      order: 6;
      text-align: right;
      align-self: flex-start;
      margin: 0 8px 0 0;

      @media (min-width: $screen-tablet-xsm) {
        align-self: center;
        width: 17%;
        order: 5;
        text-align: right;
        padding-right: 0 !important;
        margin: 0;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 13%;
        padding-right: 10px !important;
      }
    }

    &--reorder {
      width: 30%;
      order: 7;
      text-align: right;
      align-self: flex-start;
      margin: 0 8px 0 0;

      @media (min-width: $screen-tablet-xsm) {
        align-self: center;
        width: 13%;
        order: 5;
        text-align: right;
        padding-right: 10px !important;
        margin: 0;
      }
    }

    &--button {
      order: 6;
      padding-right: 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @media (min-width: $screen-tablet-xsm) {
        width: auto;
        padding-left: 20px;
      }
    }

    &--disabled {
      color: $grayColor !important;
    }
  }

  &__availability-info {
    margin: 4px 0 0;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__soldout-relation {
    margin: 8px 0 0;
  }

  &__name {
    display: block;
    color: $textBaseColor;
    font-weight: $fontWeightSemiBold;
    font-family: $fontPrimaryFamily;
    text-decoration: none;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 18px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 18px;
      line-height: 24px;

      .user-area & {
        font-size: 14px;
        line-height: 1.2;
      }
    }

    &:hover,
    &:visited {
      color: $textBaseColor;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__greeting-text,
  &__product-info,
  &__product-nr,
  &__variations,
  &__delivery-info {
    display: block;
    margin-bottom: 2px;
    color: $grayColor;
    font-size: 10px;
    line-height: 12px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__product-info {
    color: $productOrderPositionProductInfoColor;
    font-size: $productOrderPositionProductInfoFontSize;
    padding: $productOrderPositionProductInfoPadding;
    font-weight: $productOrderPositionProductInfoFontWeight;
  }

  &__delivery-info {
    .availability-info__label {
      text-align: left;
      margin: 5px 0;
    }
  }

  &__product-nr {
    display: none;

    @media (min-width: $screen-tablet-landscape) {
      display: block;
    }

    .checkout-step-summary & {
      display: none;
    }
  }

  &__variations {
    display: inline-block;
    padding: 5px 35px 5px 0;
    position: relative;
    margin: 5px 0;

    @media (min-width: $screen-desktop-sm) {
      border: 1px solid transparent;
      margin-left: -1px;
      transition: color .2s, border .2s, padding .2s;
    }
  }

  &__edit-button {
    padding: 0;
    background: none;
    border: none;
    color: $textGrayColor;

    @include iconFont(edit) {
      font-size: 17px;
    }

    @media (min-width: $screen-tablet-xsm) {
      display: none;
    }

    .checkout-step-summary & {
      display: none;
    }

    &--desktop {
      display: none;

      @media (min-width: $screen-tablet-xsm) {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      @media (min-width: $screen-desktop-sm) {
        display: none;
        cursor: pointer;
        color: $brandPrimaryColor;
        right: 8px;

        &::before {
          font-size: 15px;
        }
      }
    }
  }

  &__price {
    text-align: right;
    font-size: $productOrderPositionPriceFontSize;

    @media (max-width: $screen-mobile-landscape-max) {
      display: flex;
      justify-content: flex-end;
      min-height: 45px;
      align-items: center;
    }

    @media (min-width: $screen-tablet-xsm) {
      font-size: $productOrderPositionPriceFontSize;
    }

    .price-info--reduction {
      .price-info__current,
      .price-info__old,
      .price-info__unit {
        color: $grayDarkColor;
      }
    }

    .price-info {
      position: relative;

      &__current {
        margin: 3px 0;
        font-size: $productOrderPositionPriceInfoCurrentFontSize;

        .product-order-position__cell--price & {
          @media (max-width: $screen-mobile-landscape-max) {
            font-size: $productOrderPositionPriceInfoCurrentMobileFontSize;
          }
        }
      }

      &__old-value {
        font-size: $productOrderPositionPriceInfoOldValueMobileFontSize;

        @media (min-width: $screen-tablet-xsm) {
          font-size: $productOrderPositionPriceInfoOldValueFontSize;
        }
      }

      &__savedPercentage {
        position: absolute;
        transform: translate(100%, -5%);
        right: -4px;
      }
    }

    .price-info__currency {
      font-size: $cartPageCurrencyFontSizeProduct;

      .product-order-position__cell--summary & {
        font-size: $cartPageCurrencyFontSizeTotal;
      }
    }

    .price-info__unit {
      display: none;

      @media (min-width: $screen-tablet-xsm) {
        display: inline-block;
      }

      .product-order-position--free-item & {
        display: none;
      }
    }
  }

  &__remove {
    color: $removeColor;
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    top: -1px;
    right: 4px;

    @media (max-width: $screen-mobile-landscape-max) {
      position: absolute;
    }

    @media (min-width: $screen-tablet-xsm) {
      top: -16px;
      right: -10px;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding: $productOrderPositionRemovePadding;
      display: flex;
      top: auto;
      right: auto;
    }

    .checkout-step-summary & {
      display: none;
    }

    .product-order-position--free-item & {
      display: block;
    }

    &:visited {
      color: $textLightGrayColor;
    }

    &:hover {
      text-decoration: none;

      @media (min-width: $screen-desktop-sm) {
        color: $removeHoverColor;
      }
    }

    @include iconFont($productOrderPositionRemoveIcon) {
      font-size: 13px;

      @media (min-width: $screen-tablet-xsm) {
        position: absolute;
        right: 16px;
        top: 7px;
      }

      @media (min-width: $screen-desktop) {
        position: relative;
        right: auto;
        top: auto;
      }
    }
  }

  &__button {
    padding: 0 15px;
  }

  &__quantity-label {
    @media (min-width: $screen-tablet-xsm) {
      display: none;
    }
  }

  &__quantity-input {
    margin: 2px auto;

    @media (min-width: $screen-tablet-xsm) {
      margin: 0;
    }
  }

  &--free-item {
    background-color: $grayLighterColor;

    .product-order-position__name {
      color: $productOrderPositionNameColor;
    }

    .counter--disabled {
      border-color: $grayLighterColor;

      .counter__input--disabled,
      .counter__input[disabled] {
        background-color: $grayLighterColor;
        font-size: $fontSizeXMedium;
        -webkit-text-fill-color: $textBaseColor;
        opacity: 1;
      }
    }
  }

  &__delivery-modes {
    margin: 8px 0;

    @media (min-width: $screen-tablet-landscape) {
      margin-top: 16px;
      display: flex;
    }

    .radio-input {
      margin-bottom: 8px;

      &__label {
        padding-right: 8px;
        font-weight: $fontWeightBold;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__loader {
    position: absolute;
    top: 47px;
    left: 50%;
    transform: translateX(50px);

    @media (min-width: $screen-tablet-xsm) {
      top: 22px;
      left: 75px;
      transform: none;
    }
  }

  &__promotion {
    background: $productOrderPositionPromotionBg;
    color: $productOrderPositionPromotionText;
    font-size: $productOrderPositionPromotionFontSize;
    margin: 4px 4px 0 0;
    padding: 4px;
    text-align: center;
    display: inline-block;
  }
}
